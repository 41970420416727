import React from 'react';
import {
    Typography, Box, Grid,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import LinkIcon from '@mui/icons-material/Link';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '37px 136px 50px',
    minHeight: '223px',
};
const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};

const customCircleRoundedIcon = {
    color: colors.lightGreen,
    fontSize: 'small',
    transform: 'scale(0.75)',
    marginRight: '8px',
};

const serviceIntroductionData = {
    title: 'Colocation',
    firstParagraph: 'Servicio de housing en un datacenter siempre operativo.',
    secondParagraph: 'Alojá tus servidores en nuestro datacenter.',
    gatsbyImg: (<StaticImage src="../../images/colocation_icon.svg" alt="Datacenter" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const casesOfUseData = {
    title: 'Datos del datacenter',
    cases: [
        {
            image: (<StaticImage src="../../images/headset_icon.svg" alt="NOC" />),
            text: 'NOC y Manos remotas 24/7',
        },
        {
            image: (<StaticImage src="../../images/security_icon.svg" alt="Seguridad" />),
            text: 'Seguridad física de acceso',
        },
        {
            image: (<LinkIcon style={{ color: colors.green, height: '73px', width: '73px' }} />),
            text: '5 enlaces redundantes por BGP',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 4,
    maxWidth: { maxWidth: '993px' },
    customPadding: '42px 136px 33px',
};

const Colocation = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.burgundy,
                boxShadow: 'inset 0px -8px 20px rgba(0, 0, 0, 0.15)',
            }}
        >

            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: {padding: '48px 24px 28px' }, ...infoWrapper })}>
                <Typography variant="h4" sx={{ ...customTextTypography, fontWeight: 700 }} mb={3}>
                    Datos de la solución
                </Typography>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Typography variant="h5" style={{ lineHeight: '32px', maxWidth: '520px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Enlace dedicado y rango de IPs públicas
                        </Typography>
                        <Typography
                            variant="h5"
                            component={Link}
                            to="/services/dedicated_bandwidth"
                            style={{
                                textDecoration: 'none',
                                color: colors.green,
                                marginLeft: '18px',
                                lineHeight: '32px',
                                maxWidth: '520px',
                            }}
                        >
                            (Ver Dedicated Bandwidth)
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px', maxWidth: '520px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Firewall físico o virtual administrado por nuestros ingenieros.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: { paddingBottom: '20px' },
                        })}
                    >
                        <Typography variant="h5" style={{ lineHeight: '32px', maxWidth: '520px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Posibilidad de hacer backups contra nuestra solución de almacenamiento.
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '32px', maxWidth: '520px' }}>
                            <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            Servicios de setup e instalación gratuito.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default Colocation;
